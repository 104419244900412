html,
body,
#root {
  width: 100%;
  height: 100%;

  padding: 0;
  margin: 0;

  background-color: #e6e6e6;
}

#root,
button,
input,
label {
  font-family: 'Roboto', sans-serif;
}

button:hover {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

/* REMOVE SELECT TEXTS FOR USER */
.remove_select_text {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}
