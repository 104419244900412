.leaflet-pulsing-icon {
  border-radius: 100%;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.75);
}

.leaflet-pulsing-icon:after {
  content: '';
  border-radius: 100%;
  height: 300%;
  width: 300%;
  position: absolute;
  top: -20px;
  left: -20px;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
  }
}
